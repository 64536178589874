import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const BCMGuidesBasicCombatPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Basic Combat Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_basiccombat.png"
            alt="Reroll"
          />
        </div>
        <div className="page-details">
          <h1>Basic Combat Guide</h1>
          <h2>A guide for the combat in Black Clover Mobile!</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guide is a translation of an official guide available{' '}
          <a
            href="https://bclover-forum.vicgame.kr/view/796?page=1"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Type advantage" />
        <StaticImage
          src="../../../images/blackclover/generic/combat_1.png"
          alt="Guides"
        />
        <p>
          Each character available in Black Clover Mobile belongs to one of the
          three types - and they work like elements you know from other games:
        </p>
        <ul>
          <li>
            <strong className="teal">Technique</strong>
          </li>
          <li>
            <strong className="green">Sense</strong>
          </li>
          <li>
            <strong className="red">Power</strong>
          </li>
        </ul>
        <p>
          To make it easier for the players to spot the advantages and
          disadvantages, there's a special indicator floating above the enemies
          head:
        </p>
        <ul>
          <li>
            <strong>Great</strong> - you have type advantage,
          </li>
          <ul>
            <li>DMG +20%, Crit Rate +10%</li>
          </ul>
          <li>
            <strong>Bad</strong> - you have type disadvantage,
          </li>
          <ul>
            <li>DMG -20%, Crit Rate -10%</li>
          </ul>
          <li>
            <strong>Good</strong> - you are neutral,
          </li>
          <ul>
            <li>No bonuses or penalties.</li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/blackclover/generic/combat_2.jpg"
          alt="Guides"
        />
        <p>
          For example, Vanessa's type is{' '}
          <strong className="green">Sense</strong> and she can attack the middle
          enemy (<strong className="teal">Technique</strong> type) with type
          advantage and deal 20% more damage and gain additional 10% Crit Rate.
          But if you will decide to attack the enemies on the sides (
          <strong className="red">Power</strong> type), she will deal 20% less
          damage and have 10% decreased Crit Rate.
        </p>
        <p>Here's a summary of the type matches:</p>
        <ul>
          <li>
            <strong className="teal">Technique</strong> beats{' '}
            <strong className="red">Power</strong>, but loses to{' '}
            <strong className="green">Sense</strong>,
          </li>
          <li>
            <strong className="green">Sense</strong> beats{' '}
            <strong className="teal">Technique</strong>, but loses to{' '}
            <strong className="red">Power</strong>,
          </li>
          <li>
            <strong className="red">Power</strong> beats{' '}
            <strong className="green">Sense</strong>, but loses to{' '}
            <strong className="teal">Technique</strong>.
          </li>
        </ul>
        <SectionHeader title="Team building" />
        <StaticImage
          src="../../../images/blackclover/generic/combat_3.jpg"
          alt="Guides"
        />
        <p>
          Before entering the battle, you need to form a{' '}
          <strong>team with the mages you own</strong> (but you also can pick a
          support mage from your friends). On the right side of the screen, you
          will{' '}
          <strong>
            see the list of enemies you will encounter in the stage
          </strong>
          . Picking the mages for your team that have type advantage over the
          enemies is the best way to obtain a victory.
        </p>
        <SectionHeader title="Turn order" />
        <StaticImage
          src="../../../images/blackclover/generic/combat_4.png"
          alt="Guides"
        />
        <p>
          Each battle <strong>consist of multiple waves</strong> and you need to
          defeat all enemies in a wave to proceed to the next one. If you manage
          to kill all enemies in all waves, you will win the battle, but if all
          your mages die, you will lose it and will be forced to try again.
        </p>
        <h5>Action Gauge</h5>
        <StaticImage
          src="../../../images/blackclover/generic/combat_5.png"
          alt="Guides"
        />
        <Alert variant="primary">
          <p>
            The yellow bar located under character's HP bar is their{' '}
            <strong>Action Gauge</strong>. The Action Gauge bar fills based on
            the character's Speed stat - the higher it is, the faster the
            filling rate.{' '}
            <strong>
              Whoever reaches 100% Action Gauge, he will be able to act
            </strong>
            . And if two or more characters reach the 100% Action Gauge at the
            same time, it will be decided randomly who will move first.
          </p>
          <p>
            <strong>The turn order is also displayed on the right bar</strong> -
            you need to read the bar from bottom (closest to acting) to the top
            (will act last).
          </p>
        </Alert>
        <p>
          Let's talk above the battle visible in the screenshot above now.
          Here's the Speed values for the team the player uses:
        </p>
        <ul>
          <li>Asta - 100 Speed</li>
          <li>Charmy - 91 Speed</li>
          <li>Noelle - 110 Speed</li>
          <li>Finral - 125 Speed</li>
        </ul>
        <p>
          And as you can see the turn bar on the right places the characters
          according to their speed - Finral being the one to move first, while
          poor Charmy will move second to last (as one enemy is slower than
          her).
        </p>
        <p>
          <strong>
            Similar to other turn based games, Speed is king in Black Clover
            Mobile and investing into it will be crucial!
          </strong>
        </p>
        <SectionHeader title="Video" />
        <p>Here's a video showing the combat in action:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="10DIWKarKCY" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesBasicCombatPage;

export const Head: React.FC = () => (
  <Seo
    title="Basic Combat Guide | Black Clover M | Prydwen Institute"
    description="A guide for the combat in Black Clover Mobile!"
  />
);
